<template>
  <div class="about">
    <div id="YCF-top">
      <div class="top-titles">
        <h1>
          <b v-html="ToptTitles.title"></b>
          <br />
          <small v-html="ToptTitles.second_title"></small>
        </h1>
      </div>
      <div class="background-group">
        <div
          class="bg active"
          :style="{ backgroundImage: 'url(' + TopCovers[0] + ')' }"
          style="background-size: cover"
        ></div>
      </div>
    </div>

    <div id="YCF-about-us" class="container-fluid">
      <div class="row text-center">
        <img
          :src="AboutUs.img"
          alt="鼎創工程"
          title="鼎創工程"
          class="Company-Logo animation"
        />
        <p class="content text-left animation" v-html="AboutUs.content"></p>
      </div>
    </div>

    <section id="YCF-about-MP" class="container-fluid">
      <header>
        <h1>{{ AboutMp.title }}</h1>
        <span>{{ AboutMp.second_title }}</span>
      </header>
      <div class="row text-center">
        <div
          class="MP-card col-md-12 animation"
          v-for="item in AboutMp.cards"
          :key="item.id"
        >
          <img :src="item.image" />
          <h2>{{ item.title }}</h2>
          <p>{{ item.content }}</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { GetMetaData } from '@/common/meta.js'
export default {
  name: 'about',
  data() {
    return {
      ToptTitles: {
        title: '關於我們',
        second_title: '認真、專業、負責、守信',
      },
      TopCovers: {
        0: '/img/about/cover/Lab-Test-Background.jpg',
      },
      AboutUs: {
        img: '/img/YCF_LOGO.png',
        content:
          '本公司成立於民國七十七年八月，二十餘年來，本著穩健、誠實之經營，使公司日漸成長，目前設有工程、業務、管理及研發各部專職員工， 並延聘國內之大地工程專家、教授擔任長期顧問，以協助業主處理有關工程問題及提供完善之建議與服務。<br><br>為因應建築土木施工工法日新月異，本公司不斷延攬各種專業人才， 各專案工地工程人員組織嚴密，且均有專人專案分層負責，目前將著重於橋樑、邊坡及重要大地土木設施之自動化監測，以符合工程即時回饋系統，提供各工程業主必要時之資訊。<br><br>並於民國108年成立永嵩科技工程子公司，繼續秉持鼎創本著公司一貫之服務態度及豐富經驗，對於各項工作均全力以赴，使客戶對本公司所提供的服務均有極高的評價，本公司亦不斷的提升自我的專業職能，期能帶給客戶更專業、更周到的各項服務。',
      },
      AboutMp: {
        title: '主要代理經銷產品',
        second_title: 'Agent Brand',
        cards: [
          {
            title: '美國 GEOKON',
            content: '大地監測儀器安裝、監測、分析、維修、校正服務。',
            image: '/img/about/agent/1.png',
          },
          {
            title: '英國 G.I.',
            content: '大地監測儀器安裝、監測、分析、維修、校正服務。',
            image: '/img/about/agent/2.png',
          },
          {
            title: '美國 OLSON',
            content: '超音波檢測安裝、監測、分析、維修、校正服務。',
            image: '/img/about/agent/3.png',
          },
          {
            title: '加拿大 RST',
            content: '大地監測儀器安裝、監測、分析、維修、校正服務。',
            image: '/img/about/agent/4.png',
          },
          {
            title: '加拿大 HERON',
            content: '大地監測儀器安裝、監測、分析、維修、校正服務。',
            image: '/img/about/agent/5.png',
          },
        ],
      },
      meta_data: null,
    }
  },
  metaInfo() {
    return this.meta_data
  },
  mounted() {
    window.prerenderReady = true
  },
  created() {
    this.meta_data = GetMetaData(
      '關於我們',
      '',
      '/img/about/cover/Lab-Test-Background.jpg'
    )
  },
}
</script>
